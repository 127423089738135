import React from "react"
import { PageProps, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { Typography, makeStyles, Theme, createStyles, Container, Grid } from "@material-ui/core"
import Img from 'gatsby-image';
import Section from "../components/Section";
import SEO from "../components/seo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      flexGrow: 1,
      backgroundColor: theme.palette.secondary.main,
      padding: '6rem 0',
    },
  }),
);


export default function IndexRoute(props: PageProps) {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
        query {
            herp_ui: file(absolutePath: {regex: "/herp_ui/"}) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
        }
    `);


  return (
    <Layout>
        <SEO title="hERP Automate your processes" />
        <Section color="secondary">
                <Typography variant="h1" >herp - For a better workflow </Typography>
                <Typography variant="h2">We are building a software that let you easily digitalize your workflows without any developing knowledge. </Typography>
        </Section>

        <Section>
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <Img fluid={data.herp_ui.childImageSharp.fluid} alt="Herp UI" />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography variant="h2">The tools herp provide to enables dynamic workflow creation</Typography>
                    <ul>
                        <li>flexible entitiy management system to build the data structure you really need</li>
                        <li>create custom actions like file operations, calling webhooks or calculating your data</li>
                        <li>edit the menu to your needs to easy access the most important views</li>
                        <li>use the mobile app to trigger actions</li>
                    </ul>
                </Grid>
            </Grid>
        </Section>
    </Layout>
  )
}
